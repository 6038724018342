import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import { Container } from "react-bootstrap"
import CTA from "../../components/common/CTA"
import Multiform from "../../components/common/FormBuilder/MultiForm/MultiForm"
import Frame from "../../components/common/frame"
import Navigation from "../../components/navigation"
import Slide from "../../components/utility/slide_content"
import "../../styles/superpod.scss"
import "../../styles/superpodnew.scss"

function SuperPodNominate(props) {
  return (
    <>
      <StaticQuery
        query={graphql`
          query superpodNominate {
            SuperOps {
              pages(where: { title: "SuperpodNominate" }) {
                title
                heroImage {
                  url
                }
                pageBlock {
                  content {
                    html
                    text
                  }
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
              }
              pageComponents(where: { page: Superpod }) {
                ctaBoxes {
                  type
                  theme
                  customBackgroundColor
                  heading {
                    html
                  }
                  description {
                    html
                  }
                  isLinkVisible
                  listenLinkText
                  links {
                    section
                    icon {
                      url
                    }
                    slug
                    linkName
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div>
            {data.SuperOps.pages.map(page => {
              const { title, pageBlock, heroImage, seo } = page

              return (
                <div className="superpodmain superpodNominate">
                  <div className="pod-main mtm120 bg-black bg-dark">
                    <Frame
                      seo={seo}
                      theme="dark"
                      bodyClassName="bg-black"
                      footerClassName="plr superpod-footer"
                    >
                      <header>
                        <Navigation page={title} />
                      </header>
                      <section className="sp-banner hero-banner">
                        <Container className="Layout-container">
                          <div className="content prelative mx-auto">
                            <div className="nominate-hero">
                              <center>
                                <h1 className="heading fw-bold">
                                  {pageBlock[0].content.text}
                                </h1>
                                <p className="sub-heading mt24">
                                  {pageBlock[1].content.text}
                                </p>
                                <div className="line mt40" />
                              </center>
                              <p className="para p16 mt56">
                                {parse(pageBlock[2].content.html)}
                              </p>
                            </div>
                          </div>
                        </Container>
                      </section>

                      <section className="sp-letter sp-nominate-form">
                        <Container className="Layout-container">
                          <div className="content form-content prelative mx-auto">
                            <Multiform />
                          </div>
                        </Container>
                      </section>

                      <section className="sp-links-cta">
                        <Slide delay="200">
                          <CTA
                            data={[data.SuperOps.pageComponents[0].ctaBoxes[0]]}
                            className="Layout-container"
                            lgRight={6}
                            lgLeft={4}
                          />
                        </Slide>
                      </section>
                    </Frame>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default SuperPodNominate
